import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
      <div className="footer__top">
        <div className="footer__projects">
          <div className="footer__column-projects">
            <h3>Проекты</h3>
            <ul>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
            </ul>
          </div>
          <div className="footer__column-houses">
            <h3>Готовые дома</h3>
            <ul>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
              <li>Проект 1</li>
            </ul>
          </div>
        </div>
        <div className="footer__contacts">
          <div className="footer__column-clients">
            <h3>Клиентам</h3>
            <ul>
              <li>Галерея домов</li>
              <li>Индивидуальные проекты</li>
              <li>Этапы работы</li>
              <li>Новости</li>
              <li>Акции</li>
              <li>Отзывы</li>
            </ul>
          </div>
          <div className="footer__column-contacts">
            <h3>Связь</h3>
            <ul>
              <li>info@db.house</li>
              <li>Telegram</li>
              <li>What's App</li>
              <li>8-917-850-02-22</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <p className="footer__disclaimer">
          *Meta Platform INC — запрещенная организация на территории РФ
        </p>
      </div>
      <div className="footer__logo"><img src="/img/big-logo.png" alt="Логотип" /></div>
      </div>
    </footer>
  );
};

export default Footer;
