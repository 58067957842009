import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import content from "../../content.json";
import "../Reviews/Reviews.css";
import ReviewsCard from '../ReviewsCard/ReviewsCard';

const Reviews = () => {
  const reviews = content.reviews;

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, [prevRef, nextRef]);

  return (
    <div className="reviews">
      <div className="reviews__top">
        <h2 className="reviews__title">
          Приятные слова от тех, с кем уже работали
        </h2>
        <div className="swiper-navigation">
          <div ref={prevRef} className="button-prev">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
              <path
                d="M11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7V9ZM0.292893 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM11 7L1 7V9L11 9V7Z"
                fill="black"
              />
            </svg>
          </div>
          <div ref={nextRef} className="button-next">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
              <path
                d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM11.7071 8.70711C12.0976 8.31658 12.0976 7.68342 11.7071 7.29289L5.34315 0.928932C4.95262 0.538408 4.31946 0.538408 3.92893 0.928932C3.53841 1.31946 3.53841 1.95262 3.92893 2.34315L9.58579 8L3.92893 13.6569C3.53841 14.0474 3.53841 14.6805 3.92893 15.0711C4.31946 15.4616 4.95262 15.4616 5.34315 15.0711L11.7071 8.70711ZM1 9L11 9V7L1 7L1 9Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        modules={[Navigation]}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {reviews.map((review) => (
          <SwiperSlide key={review.id}>
            <ReviewsCard review={review} />
          </SwiperSlide>
        ))}
      </Swiper>
      <a href="#all-reviews" className="reviews__link">
        Смотреть все
      </a>
    </div>
  );
};

export default Reviews;
