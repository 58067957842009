import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import emailjs from "emailjs-com";
import { Navigation } from "swiper/modules";
import "../HouseFeedback/HouseFeedback.css";
import content from "../../content.json";

SwiperCore.use([Navigation]);

const HouseFeedback = () => {
  //
  const house = content.houses;
  const houses = content.readyHouses;
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "your_service_id", // Ваш ID сервиса
        "your_template_id", // Ваш ID шаблона
        form.current,
        "your_user_id" // Ваш публичный ключ пользователя
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Заявка отправлена!");
        },
        (error) => {
          console.log(error.text);
          alert("Ошибка отправки заявки.");
        }
      );
  };

  //
  return (
    <div className="feedback-container">
      <div className="feedback-container__content">
        <h1 className="feedback-container__title">
          Строительство домов по индивидуально разработанным проектам
        </h1>
        <div className="feedback-section">
          <div className="feedback-section__slider">
            {/* Слайдер домов */}
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              loop={true}
            >
              {houses.map((house) => (
                <SwiperSlide key={house.id}>
                  <div className="header-slider-image">
                    <img src={house.image} alt={house.title} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* Кнопки навигации */}
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </div>

          <div className="feedback-section__form">
            <div className="feedback-section__form-container">
              <h2>
                У вас есть проект дома, который вы хотите реализовать? Мы с
                удовольствием готовы помочь вам, оставьте заявку и наш менеджер
                вас проконсультирует
              </h2>
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="name"
                  placeholder="Ваше имя"
                  required
                />
                <input type="tel" name="phone" placeholder="Телефон" required />
                <select name="contact_method" required>
                  <option value="variable">Способ связи:</option>
                  <option value="email">Email</option>
                  <option value="phone">Телефон</option>
                </select>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    required
                    className="checkbox-confirm"
                  />
                  <label>
                    Нажимая, вы соглашаетесь с политикой конфиденциальности
                  </label>
                </div>
                <button type="submit">Оставить заявку</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseFeedback;
