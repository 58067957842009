import React from "react";
import "./HeaderSlider.css";
import content from "../../content.json";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import SliderCard from "../SliderCard/SliderCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useRef, useState, useEffect } from "react";

const HeaderSlider = () => {
  const houses = content.houses;
  const swiperRef = useRef(null);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getImage = (house) => {
    if (screenWidth <= 767) {
      return house.image480;
    } else if (screenWidth <= 1024) {
      return house.image1024;
    } else {
      return house.image;
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="header-slider">
    <Swiper spaceBetween={50} slidesPerView={1}>
    {houses.map((house) => (
        <SwiperSlide key={house.id} className="header-slider-swiper">
        <div className="header-slider__slide">
          <div className="header-slider__text">
            <h1>Готовые дома из кирпича в Москве и МО</h1>
            <div className="header-slider__image-mobile">
                <img 
                  src={getImage(house)} 
                  alt={house.title} 
                />
              </div>
            <p>
              С полным контролем строительства и индивидуальным подходом: въезжайте в свой новый дом с комфортом и уверенностью в качестве, не тратя время на долгие стройки.
            </p>
          </div>
          <div className="header-slider__image">
          <img 
                src={getImage(house)} 
                alt={house.title} 
              />
            <button className="header-slider__button">Заказать проект</button>
          </div>
        </div>
        <button className="header-slider__button-mini">Заказать проект</button>
        </SwiperSlide>
      ))}
      {/* Добавьте дополнительные слайды, если нужно */}
    </Swiper>
    </div>
  );
};

export default HeaderSlider;
