import React from 'react';
import './Promotions.css';
import content from "../../content.json";

const Promotions = () => {
  const promotionsData = content.promotions

  return (
    <div className="promotions">
      <div className="promotions__top">
        <h2>Акции компании</h2>
        <a href="#all-promotions">смотреть все</a>
      </div>
      <div className="promotions__list">
        {promotionsData.map((promo) => (
          <div key={promo.id} className="promotion-card">
            <div className="promotion-card__image">
              <img src={promo.image} alt={promo.title} />
            </div>
            <div className="promotion-card__content">
              <h3>{promo.title}</h3>
              <p>{promo.description}</p>
            </div>
          </div>
        ))}
      </div>
      <a className='promotions__all-mini' href="#all-promotions">смотреть все</a>
    </div>
  );
};

export default Promotions;
