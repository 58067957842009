import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../About/About.css";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import content from "../../content.json";
import { useRef, useState, useEffect } from "react";

const About = () => {
  const slides = content.about;

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  return (
    <div className="about">
      <div className="about__title">
        <h1>
          Дому Быть — это компания, где забота о клиенте стоит на первом месте.
          Мы создаем дома, полностью адаптированные под ваш образ жизни, и на
          каждом этапе стремимся максимально снизить вашу нагрузку.{" "}
        </h1>
      </div>

      <div className="about__content">
        {/* Слайдер */}
        <div className="about__slider">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            className="about-slider"
            modules={[Navigation]} 
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              if (prevRef.current && nextRef.current && swiper) {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }}
            onSlideChange={() => {
              if (swiperRef.current) {
                swiperRef.current.navigation.update();
              }
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="about__slide">
                  <img
                    src={slide.img}
                    alt={slide.name}
                    className="about__image"
                  />
                  <div className="about__image-mobile">
                    <img src={slide.img480} alt={slide.title} />
                    <div className="about__swiper-navigation">
                      <div ref={prevRef} className="about__button-prev">
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7V9ZM0.292893 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM11 7L1 7V9L11 9V7Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div ref={nextRef} className="about__button-next">
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9L1 7ZM11.7071 8.70711C12.0976 8.31658 12.0976 7.68342 11.7071 7.29289L5.34315 0.928932C4.95262 0.538408 4.31946 0.538408 3.92893 0.928932C3.53841 1.31946 3.53841 1.95262 3.92893 2.34315L9.58579 8L3.92893 13.6569C3.53841 14.0474 3.53841 14.6805 3.92893 15.0711C4.31946 15.4616 4.95262 15.4616 5.34315 15.0711L11.7071 8.70711ZM1 9L11 9V7L1 7L1 9Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="about__description">
                    <h3>{slide.name}</h3>
                    <p>{slide.position}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Текстовый блок */}
        <div className="about__text">
          <p>
            Наши эксперты помогут вам с одобрением ипотеки, подберут идеальный
            участок, проведут необходимые геологические исследования и
            скорректируют проект в соответствии с вашими запросами.
          </p>
          <p>
            Мы формируем подробную смету и фиксируем цену, гарантируя отсутствие
            неожиданных доплат по ходу строительства. Даже после завершения
            строительства мы остаемся рядом, помогая с благоустройством
            территории, ремонтом и строительством объектов инфраструктуры на
            вашем участке.
          </p>
          <p>
            Наши дома — это не только комфорт и качество, но и уверенность в
            том, что все заботы мы берем на себя.
          </p>
          <button className="about__button">Подробнее о команде</button>
        </div>
      </div>
      <button className="about__button-mini">Подробнее о команде</button>
    </div>
  );
};

export default About;
