import React, { useState } from "react";
import "../Questions/Questions.css";
import content from "../../content.json";

const Questions = () => {
  const [activeQuestion, setActiveQuestion] = useState(content.questions[0].id);

  const toggleQuestion = (id) => {
    setActiveQuestion(activeQuestion === id ? null : id);
  };

  return (
    <div className="questions">
      <h2>Как мы работаем?</h2>
      <div className="questions-container">
        <div className="questions-column">
          {content.questions.slice(0, 5).map((question) => (
            <div key={question.id} className="question-item">
              <div
                className="question-title"
                onClick={() => toggleQuestion(question.id)}
              >
                {question.id}. {question.question}
                <span>{activeQuestion === question.id ? "▲" : "▼"}</span>
              </div>
              {activeQuestion === question.id && (
                <div className="question-content">
                  <p>{question.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="questions-column">
          {content.questions.slice(5).map((question) => (
            <div key={question.id} className="question-item">
              <div
                className="question-title"
                onClick={() => toggleQuestion(question.id)}
              >
                {question.id}. {question.question}
                <span>{activeQuestion === question.id ? "▲" : "▼"}</span>
              </div>
              {activeQuestion === question.id && (
                <div className="question-content">
                  <p>{question.answer}</p>
                </div>
              )}
            </div>
          ))}

          {/* Блок с текстом сразу после 10-го вопроса */}
          <div className="additional-text">
            <p>
            Каждый из этих этапов направлен на то, чтобы процесс строительства дома был максимально комфортным для вас, с полным контролем и минимальными хлопотами с вашей стороны.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
