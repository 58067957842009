import React from 'react';
import './ConsultationFeedback.css';

const ConsultationFeedback = () => {
  return (
    <div className="consultation-feedback">
      <div className="consultation-feedback__content">
        <div className="consultation-feedback__title">
          <h2>Не знаете, с чего начать?</h2>
          <p>Оставьте заявку на бесплатную консультацию и посетите наши строящиеся объекты!</p>
        </div>
        <div className="consultation-feedback__description">
          <p>
            Наши специалисты помогут вам подобрать идеальный земельный участок, получить одобрение
            ипотеки на самых выгодных условиях, а также приглашают лично убедиться в качестве наших домов.
            Мы организуем для вас посещение строящихся объектов и уже готовых домов, чтобы вы могли оценить
            наше внимание к деталям и высокий стандарт строительства. Заполните форму, и мы свяжемся с вами,
            чтобы обсудить все детали и организовать визит к вашему будущему дому мечты!
          </p>
        </div>
      </div>
      <div className="consultation-feedback__button-wrapper">
        <button className="consultation-feedback__button">Записаться на консультацию</button>
        </div>
    </div>
  );
};

export default ConsultationFeedback;
