import React from "react";
import "../HouseCard/HouseCard.css";
import { useEffect, useState } from "react";

const HouseCard = ({ house }) => {
  return (
    <div className="house">
      <div className="house__image">
        <img src={house.image} alt={house.title}  />
        <div>
          <p className="">
            {house.bathroom}
            <span /> 
            {house.room}
          </p>
        </div>
      </div>
      <div className="house__info">
        <h3 className="house__title">{house.title}</h3>
        <p className="house__price">от {house.price} Р</p>
      </div>
      <div>
        <div className="house__area">
          <p>площадь: </p>
          <h5 class="underline">{house.area} кв.м</h5>
        </div>
        <div className="house__material">
          <p>материал: </p>
          <h5 class="underline">{house.material}</h5>
        </div>
        <div className="house__distance">
          <p>удаленность от МКАД: </p>
          <h5 class="underline">{house.distance}</h5>
        </div>
      </div>
      <p className="house__price-mini">от {house.price} Р</p>
      <button className="house__button">Подробнее</button>
    </div>
  );
};

export default HouseCard;
