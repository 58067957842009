import React from "react";
import "./Home.css";
import HeaderSlider from "../../components/HeaderSlider/HeaderSlider";
import Header from "../../components/Header/Header";
import Advantage from "../../components/Advantage/Advantage";
import HouseSlider from "../../components/HouseSlider/HouseSlider";
import ProjectsSlider from "../../components/ProjectsSlider/ProjectsSlider";
import HouseFeedback from "../../components/HouseFeedback/HouseFeedback";
import Questions from "../../components/Questions/Questions";
import GuideFeedback from "../../components/GuideFeedback/GuideFeedback";
import About from "../../components/About/About";
import Reviews from "../../components/Reviews/Reviews";
import ConsultationFeedback from "../../components/ConsultationFeedback/ConsultationFeedback";
import Promotions from "../../components/Promotions/Promotions";
import Contacts from "../../components/Contacts/Contacts";
import Footer from "../../components/Footer/Footer";


const Home = () => {
  return (
    <div className="home">
      <div className="background-image">
        <Header />
        <HeaderSlider />
        <Advantage/>
        <HouseSlider/>
        <ProjectsSlider/>
        <HouseFeedback/> 
        <Questions/>
        <GuideFeedback/>
        <About/>
        <Reviews/>
        <ConsultationFeedback/>
        <Promotions/>
        <Contacts/>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
