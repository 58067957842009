import React, { useState } from "react";
import "./GuideFeedback.css";

const GuideFeedback = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    contactMethod: "",
    agreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Логика отправки данных формы
    console.log(formData);
  };

  return (
    <div className="guide-feedback">
      <div className="guide-feedback__title">
        <h1>Получите бесплатную консультацию и скачайте полезный гайд!</h1>
        <p>
          Оставьте заявку на консультацию по ипотеке от наших экспертов и
          получите бесплатный гайд по льготным ипотечным программам, которые
          действуют в 2024 году.
        </p>
        
      </div>
        <div className="guide-feedback__content">
          <div className="guide-feedback__images">
            <img src="/img/GuideFeedback/img1.png" alt="Guide page 1" className="guide-feedback__image-1"/>
            <img src="/img/GuideFeedback/img2.png" alt="Guide page 2" className="guide-feedback__image-2"/>
          </div>

          <form className="guide-feedback__form" onSubmit={handleSubmit}>
            <ul>
              <li>
                <p>
                Простое и понятное объяснение всех актуальных программ с
                пониженной ставкой.
                </p>
              </li>
              <li>
                <p>
                Информацию о государственных выплатах, которые можно
                использовать для первоначального взноса и досрочного погашения
                ипотеки.
                </p>
              </li>
            </ul>
            <div className="container__form">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Ваше имя"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="input-name"
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Телефон"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="input-phone"
                />
              </div>
            </div>
            <div className="form-group">
              <select
                name="contactMethod"
                placeholder="Способ связи"
                value={formData.contactMethod}
                onChange={handleChange}
                required
              >
                <option value="">Способ связи</option>
                <option value="phone">Телефон</option>
                <option value="email">Email</option>
              </select>
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  name="agreement"
                  checked={formData.agreement}
                  onChange={handleChange}
                  required
                />
                Нажимая, Вы соглашаетесь с политикой конфиденциальности
              </label>
            </div>
            <button type="submit" className="submit-button">
              Получить
            </button>
          </form>
        </div>
    </div>
  );
};

export default GuideFeedback;
