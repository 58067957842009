import React, { useState } from "react";
import "./Contacts.css";

const Contacts = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    contactMethod: "",
    agreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Логика отправки данных формы
    console.log(formData);
  };

  return (
    <div className="contacts">
      <div className="contacts__content">
        <div className="contacts__contacts">
          <h2 className="contacts__title">
            Свяжитесь с нами, удобным для вас способом
          </h2>
          <p className="contacts__text">info@db.house</p>
          <p className="contacts__text">8-917-850-02-22</p>
          <p className="contacts__text">info@db.house</p>
          <ul className="contacts__icons">
            <li className="contacts__icon">
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="25" cy="25" r="24.5" stroke="#F0CA00" />
              </svg>
            </li>
            <li className="contacts__icon"><svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="25" cy="25" r="24.5" stroke="#F0CA00" />
              </svg></li>
            <li className="contacts__icon"><svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="25" cy="25" r="24.5" stroke="#F0CA00" />
              </svg></li>
          </ul>
        </div>
        <form className="contacts__form" onSubmit={handleSubmit}>
          <div className="contacts__form-group">
            <label></label>
            <input
              type="text"
              name="name"
              placeholder="Ваше имя:"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contacts__form-group">
            <label></label>
            <input
              type="email"
              name="email"
              placeholder="email:"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contacts__form-group">
            <label></label>
            <input
              type="tel"
              name="phone"
              placeholder="Телефон:"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contacts__form-group">
            <label></label>
            <input
              type="text"
              name="comment"
              placeholder="Комментарий:"
              value={formData.comment}
              onChange={handleChange}
              required
            />
          </div>
          <div className="contacts__checkbox">
            <label>
              <input
                type="checkbox"
                name="agreement"
                checked={formData.agreement}
                onChange={handleChange}
                required
              />
              Нажимая, Вы соглашаетесь с политикой конфиденциальности
            </label>
          </div>
          <button type="submit" className="contacts__submit-button">
            Заказать звонок
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contacts;
