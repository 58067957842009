import React from "react";
import "./Advantage.css";
import content from "../../content.json";

const Advantage = () => {

  const advantagesData = content.advantages;
  
  return (
    <div className="advantages-container">
      
      <div className="advantages-grid">
      <h2 className="advantages-title">Преимущества сотрудничества с нами</h2>
      <h2 className="advantages-zero"></h2>
        {advantagesData.map((advantage, index) => (
          <div
            key={index}
            className={`advantage-item ${
              advantage.highlight ? "highlight" : ""
            }`}
          >
            <h3>{advantage.title}</h3>
            <p>{advantage.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advantage;
