import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../pages/img/logo.svg";
import tel from "../../pages/img/tel.svg";

const Header = () => {
  return (
    <header className="header">
      <Link to="/" className="header__link-logo">
        <img src={logo} alt="Логотип" className="header__logo" />
      </Link>
      <nav className="header__nav">
        <Link to="/portfolio" className="header__link">
          Готовые дома
        </Link>
        <Link to="/projects" className="header__link">
          Проекты
        </Link>
        <Link to="/gallery" className="header__link">
          Галерея
        </Link>
        <Link to="/about" className="header__link">
          О компании
        </Link>
        <Link to="/news" className="header__link">
          Новости
        </Link>
        <Link to="/contacts" className="header__link">
          Контакты
        </Link>
        <Link to="/reviews" className="header__link">
          Отзывы
        </Link>
      </nav>
      <div className="header__call">
        <button className="header__call-text">Обратный звонок</button>
        <img className="header__call-button" src={tel} alt="Позвонить" />
      </div>
      <button className="header__menu">
        <svg
          width="50px"
          height="50px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 6.5H19V8H5V6.5Z" fill="#FFFFFF" />
          <path d="M5 16.5H19V18H5V16.5Z" fill="#FFFFFF" />
          <path d="M5 11.5H19V13H5V11.5Z" fill="#FFFFFF" />
        </svg>
      </button>
    </header>
  );
};

export default Header;
