import React, { useEffect, useRef } from "react";
import "../ReviewsCard/ReviewsCard.css";

const ReviewsCard = ({ review }) => {
    return (
      <div className="review__card">
        <div className="review__card-top">
          <div className="review__avatar">{review.name[0]}</div>
          <h3 className="review__name">{review.name}</h3>
          <div className="review__info">
            <p>{review.date}</p>
            <p>{review.house}</p>
            <p>{review.phone}</p>
          </div>
        </div>
        <div className="review__content">
          <p>{review.text}</p>
        </div>
      </div>
    );
  };
  
  export default ReviewsCard;